import React, { useContext } from "react"
import ContentStationDetails from "../components/ContentStationDetails"
import { AppContext } from "../contexts/AppContext"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const StationDetailsTemplate = props => {
  let { selectedCountry } = useContext(AppContext)
  return selectedCountry ? (
    <ContentStationDetails {...props.pageContext} location={props.location} />
  ) : null
}

export default StationDetailsTemplate
