import React, { useContext, useState } from "react"
// import { withPrefix } from "gatsby"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import {
  QUERY_STATION_BY_ID,
  QUERY_STATIONS_BY_SLUG,
} from "../../queries/page/station"
import { AppContext } from "../../contexts/AppContext"
import * as St from "../Styled"
import StationBox from "./StationBox"
import PresenterHList from "./PresenterHList"
import PodcastHList from "./PodcastHList"
import ScheduleVList from "./ScheduleVList"
import VideoHList from "./VideoHList"
import * as C from "./common"
import { MOBILE, LANDSCAPE, TAB } from "../../constants/breakpoints"
import { Link } from "gatsby"
import btnAnchorPrev from "../../images/prev.png"
import btnAnchorNext from "../../images/next.png"

import {
  convertToSlug,
  stationPodcastsPageURL,
  stationVideosPageURL,
  PROP_FONT_PRIMARY
} from "../../constants/props"
import moment from "moment"
import SEO from "../Seo"


const basePrefix = `${process.env.STRAPI_SERVER_URL}`

const PresenterHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`
const DayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 16vw;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
`
const DayTitle = styled.div`
  letter-spacing: 1.5px;
  font-size: ${MOBILE.schedule.dayFont}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.schedule.dayFont}px;
  }
  font-family: ${PROP_FONT_PRIMARY};
`
const PodcastHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`
const VideoHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`
const ScheduleVListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`
const LinkPage = styled(Link)`
  cursor: pointer;
  color: #fff;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(65px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`

const helper_broadcastFreq = data => {
  if (!data || data.length === 0) return null
  let output = ""
  data.forEach(({ origin, frequency }, index) => {
    if (index < data.length - 2) {
      output += `${origin} (${frequency}), `
    } else if (index === data.length - 2) {
      output += `${origin} (${frequency}) `
    } else if (data.length > 1 && index === data.length - 1) {
      output += ` and ${origin} (${frequency})`
    } else {
      output += `${origin} (${frequency})`
    }
  })
  return output
}

const daysList = [
  { short: "sun", title: "Sunday" },
  { short: "mon", title: "Monday" },
  { short: "tue", title: "Tuesday" },
  { short: "wed", title: "Wednesday" },
  { short: "thu", title: "Thursday" },
  { short: "fri", title: "Friday" },
  { short: "sat", title: "Saturday" },
]

const helper_search = (a, v) => {
  const retFloatTime = str =>
    parseInt(str.split(":")[0]) + parseInt(str.split(":")[1]) / 100

  if (!a.length || retFloatTime(a[0]["from"]) > retFloatTime(v["from"])) {
    return 0
  }
  let i = 1
  while (
    i < a.length &&
    !(
      retFloatTime(a[i]["from"]) > retFloatTime(v["from"]) &&
      retFloatTime(a[i - 1]["from"]) <= retFloatTime(v["from"])
    )
  ) {
    i = i + 1
  }
  return i
}

const helper_schedule = shows => {
  let output = {
    sun: [],
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
  }

  if (!shows || shows.length === 0) return output
  daysList.forEach((name, d) => {
    shows.forEach((show, s) => {
      show[name.short].forEach((episode, e) => {
        output[name.short].splice(
          helper_search(output[name.short], episode),
          0,
          episode
        )
      })
    })
  })

  return output
}

const helper_podcasts = podcasts => {
  if (!podcasts || !podcasts.length) return []

  const result = podcasts.map((podcast, _) => {
    const v = {
      ...podcast,
      width: "135",
      height: "135",
      image: {
        ...podcast.image,
        url:
          podcast.image && podcast.image && podcast.image.url
            ? podcast.image.url.startsWith("http")
              ? podcast.image.url
              : basePrefix + podcast.image.url
            : "",
      },
    }
    return v
  })
  return result
}

const helper_videos = videos => {
  if (!videos || !videos.length) return []

  const result = videos.map((video, _) => {
    const v = {
      ...video,
      clickableTitle: false,
      width: "320",
      height: "180",
      image:
        video.image && video.image && video.image.url
          ? video.image.url.startsWith("http")
            ? video.image.url
            : basePrefix + video.image.url
          : "",
      subtitles: [
        video.show ? video.show.title : "",
        `${video.station.title} • ${moment(video.updatedAt)
          .startOf("seconds")
          .fromNow()}`,
      ],
    }
    return v
  })
  return result
}

const helper_presenters = presenters => {
  if (!presenters || !presenters.length) return []

  return presenters.map((presenter, _) => {
    return {
      ...presenter,
      image: {
        url:
          presenter && presenter.image && presenter.image.url
            ? presenter.image.url.startsWith("http")
              ? presenter.image.url
              : basePrefix + presenter.image.url
            : "",
      },
    }
  })
}

const StationContentDetails = props => {
  const { selectedCountry } = useContext(AppContext)

  const [pageState, setPageState] = useState({
    scheduleDay: moment().weekday(),
    neighbours: props.neighbours,
    data: {
      station: {
        ...props.data.station,
        frequencyByPlace: helper_broadcastFreq(
          props.data.station && props.data.station.broadcastfreqs
        ), //Temp
        presenters: helper_presenters(
          props.data.station && props.data.station.presenters
        ),
        schedule: helper_schedule(
          props.data.station && props.data.station.schedule
        ),
        podcasts: helper_podcasts(
          props.data.station && props.data.station.podcasts
        ),
        videos: helper_videos(props.data.station && props.data.station.videos),
      },
    },
  })

  const onNextDaySchedule = () => {
    setPageState({
      ...pageState,
      scheduleDay:
        pageState.scheduleDay + 1 > 6 ? 0 : pageState.scheduleDay + 1,
    })
  }

  const onPrevDaySchedule = () => {
    setPageState({
      ...pageState,
      scheduleDay:
        pageState.scheduleDay - 1 < 0 ? 6 : pageState.scheduleDay - 1,
    })
  }

  const stationID =
    props.data && props.data.station && props.data.station.id
      ? props.data.station.id
      : "all"
  const stationNameSlug =
    props.data && props.data.station && props.data.station.title
      ? convertToSlug(props.data.station.title)
      : "all"

  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        {pageState && pageState.data && pageState.data.station && (
          <StationBox station={pageState.data.station} />
        )}

        {pageState.data.station.presenters.length > 0 && (
          <C.GrayBox>
            <C.GrayBoxContent>
              <PresenterHListWrapper>
                <C.SubcategoryTitle>Presenters</C.SubcategoryTitle>
                <PresenterHList data={pageState.data.station.presenters} />
              </PresenterHListWrapper>
              {pageState.data.station.schedule ? (
                <ScheduleVListWrapper>
                  <C.SubcategoryTitle style={{ margin: "0" }}>
                    Schedule
                  </C.SubcategoryTitle>
                  <DayWrapper>
                    <DayTitle>{daysList[pageState.scheduleDay].title}</DayTitle>
                    <C.PreviousNextWrapper style={{ paddingLeft: "10px" }}>
                      <C.PrevAnchor
                        src={btnAnchorPrev}
                        onClick={onPrevDaySchedule}
                      />
                      <C.NextAnchor
                        src={btnAnchorNext}
                        onClick={onNextDaySchedule}
                      />
                    </C.PreviousNextWrapper>
                  </DayWrapper>
                  <ScheduleVList
                    data={
                      daysList[pageState.scheduleDay].short in
                      pageState.data.station.schedule
                        ? pageState.data.station.schedule[
                            daysList[pageState.scheduleDay].short
                          ]
                        : []
                    }
                  />
                </ScheduleVListWrapper>
              ) : null}
            </C.GrayBoxContent>
          </C.GrayBox>
        )}
        <St.CenterContentSection>
          {(pageState.data.station.podcasts &&
            pageState.data.station.podcasts.length > 0) ||
          (pageState.data.station.videos &&
            pageState.data.station.videos.length > 0) ? (
            <St.CenterContentWrapper>
              {pageState.data.station.podcasts &&
              pageState.data.station.podcasts.length > 0 ? (
                <PodcastHListWrapper>
                  <St.PodcastSliderHeading>
                    <LinkPage
                      to={
                        selectedCountry && selectedCountry.id
                          ? stationPodcastsPageURL(
                              stationID,
                              stationNameSlug,
                              selectedCountry.id,
                              convertToSlug(selectedCountry.name)
                            )
                          : "/"
                      }
                    >
                      Podcasts
                    </LinkPage>
                  </St.PodcastSliderHeading>
                  <PodcastHList
                    data={pageState.data.station.podcasts}
                    station={{
                      id: stationID,
                      slug: stationNameSlug,
                      country: pageState.data.station.country
                    }}
                  />
                </PodcastHListWrapper>
              ) : null}
              {pageState.data.station.videos &&
              pageState.data.station.videos.length > 0 ? (
                <VideoHListWrapper>
                  <St.VideoSliderHeading>
                    <LinkPage
                      to={
                        selectedCountry
                          ? stationVideosPageURL(
                              stationID,
                              stationNameSlug,
                              selectedCountry.id,
                              convertToSlug(selectedCountry.name)
                            )
                          : "/"
                      }
                    >
                      Videos
                    </LinkPage>
                  </St.VideoSliderHeading>

                  <VideoHList
                    data={pageState.data.station.videos}
                    station={{
                      id: stationID,
                      slug: stationNameSlug,
                      country: pageState.data.station.country
                    }}
                  />
                </VideoHListWrapper>
              ) : null}
            </St.CenterContentWrapper>
          ) : (
            <St.NoDataMessage>
              Stay tuned for Podcasts and Videos from this station
            </St.NoDataMessage>
          )}
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

const ContentAudioStation = ({
  location,
  seoStation,
  seoTitle,
  seoDescription,
  seoImage,
  seoImageWidth,
  seoImageHeight,
}) => {
  const pathComponents =
    location && location.pathname
      ? location.pathname.split("/").filter(p => p)
      : []

  let stationSlug = pathComponents.length === 2 ? pathComponents[1] : ""

  let { data, loading } = useQuery(QUERY_STATIONS_BY_SLUG, {
    variables: {
      slug: stationSlug,
    },
    fetchPolicy: "network-only",
  })

  if (typeof window === `undefined` && seoStation && seoStation.station) {
    // Window undefined means that component is being rendered during gatsby build
    // so we can use data passed during that to render static html
    return (
      <>
        <SEO
          {...{
            seoTitle,
            seoDescription,
            seoImage,
            seoImageWidth,
            seoImageHeight,
          }}
        />
        <StationContentDetails data={seoStation} />
      </>
    )
  } else if (!loading && data && data.stations && data.stations.length) {
    return (
      <>
        <SEO
          {...{
            seoTitle,
            seoDescription,
            seoImage,
            seoImageWidth,
            seoImageHeight,
          }}
        />
        <StationContentDetails data={{ station: data.stations[0] }} />
      </>
    )
  } else return null
}

export default ContentAudioStation
