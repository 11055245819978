import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"

export const GrayBox = styled.div`
  background: #464a52;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  justify-content: center;
  border-radius: ${MOBILE.box.borderRadius}px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.width}vw;
    margin: 15px auto;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    padding: 30px 0px;
    width: ${LANDSCAPE.box.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 30px 0px;
    width: ${TAB.box.width}px;
    border-radius: ${TAB.box.borderRadius}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.width}px;
  }
`
export const GrayBoxContent = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.contentWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`
export const SubcategoryTitle = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  ::selection {
    color: none;
    background: none;
  }
  letter-spacing: 2px;
  font-size: ${MOBILE.box.font.mainHeading}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.font.mainHeading}px;
  }
`
export const PrevAnchor = styled.img`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: ${MOBILE.box.anchorHeight}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.box.anchorHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.box.anchorHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.box.anchorHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.box.anchorHeight}px;
  }
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`
export const NextAnchor = styled.img`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: ${MOBILE.box.anchorHeight}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.box.anchorHeight}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.box.anchorHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.box.anchorHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.box.anchorHeight}px;
  }
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  margin-left: 30px;
`
export const PreviousNextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
`
