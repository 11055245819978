import React from "react"
import styled from "styled-components"

import {
  PROP_FONT_SECONDARY,
  PROP_FONT_PRIMARY,
} from "../../../constants/props"

import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../../constants/breakpoints"

const PresneterCardWrappper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  & div {
    align-self: center;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.cardWidth}px;
  }
`

const PresenterImg = styled.img`
  border-radius: 50%;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.image.widthHeight}vw;
    height: ${MOBILE.presenters.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.image.widthHeight}vw;
    height: ${LANDSCAPE.presenters.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.image.widthHeight}px;
    height: ${TAB.presenters.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.image.widthHeight}px;
    height: ${MEDIUM.presenters.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.image.widthHeight}px;
    height: ${LARGE.presenters.slider.image.widthHeight}px;
  }
`
const Title = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  padding: 0.6vw 0 0 0;
  text-overflow: ellipsis;
  text-align: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: ${MOBILE.presenters.slider.font.title}px;
    width: ${MOBILE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.presenters.slider.font.title}px;
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.presenters.slider.font.title}px;
    width: ${TAB.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.presenters.slider.font.title}px;
    width: ${MEDIUM.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.presenters.slider.font.title}px;
    width: ${LARGE.presenters.slider.cardWidth}px;
  }
`
const Subtitle = styled.div`
  list-style: none;
  text-overflow: ellipsis;
  overflow: visible;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  text-align: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.cardWidth}vw;
    font-size: ${MOBILE.presenters.slider.font.content}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
    font-size: ${LANDSCAPE.presenters.slider.font.content}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.cardWidth}px;
    font-size: ${TAB.presenters.slider.font.content}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.cardWidth}px;
    font-size: ${MEDIUM.presenters.slider.font.content}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.cardWidth}px;
    font-size: ${LARGE.presenters.slider.font.content}px;
  }
`

const PresenterCard = props => {
  const showsString =
    props.shows && props.shows.length
      ? props.shows.length > 1
        ? props.shows[0].title + ", " + props.shows[1].title
        : props.shows[0].title
      : ""
  return (
    <PresneterCardWrappper>
      <PresenterImg
        src={props.image && props.image.url ? props.image.url : ""}
      />

      <Title>{props.title}</Title>
      <Subtitle>{showsString}</Subtitle>
    </PresneterCardWrappper>
  )
}

export default PresenterCard
