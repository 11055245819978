import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  DATA_TYPE_STATION_PODCAST,
  convertToSlug,
} from "../../../constants/props"
import { stationPodcastsShareURL } from "../../../constants/share"
import { AppContext } from "../../../contexts/AppContext"
import * as St from "../../Styled"
import useRecordUserData from "../../../hooks/useRecordUserData"
import LikeAndShare from "../../LikeAndShare"
import styled from "styled-components"
import { TAB } from "../../../constants/tab"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`

const PodcastCard = props => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, device, userState, ctxPlayer, selectedCountry } = useContext(AppContext)
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_STATION_PODCAST,
    id: props.id,
    user: userState ? userState.id : null,
    viewed: props.viewed != null ? props.viewed : 0,
  })

  const isPlaying =
    ctxPlayer.id === props.id &&
      ctxPlayer.dataType === DATA_TYPE_STATION_PODCAST &&
      ctxPlayer.playing
      ? true
      : false

  console.log("props are, ", props, selectedCountry)

  const countrySlug = props.station.country
    ? convertToSlug(props.station.country.title)
    : ""


  const image =
    props.image && props.image.url
      ? props.image.url.startsWith("http")
        ? props.image.url
        : basePrefix + props.image.url
      : ""

  return (
    <St.PodcastSliderWrappper>
      <St.PodcastSliderImageWrapper>
        <StyledImage
          image={props.image.url}
          width={"100%"}
          height={"100%"}
          premium={props.premium}
          playing={isPlaying}
          imageAlt={props.title}
          borderRadius={
            getConfigValue(device, "podcasts.slider.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO, // podcasts are just audios
                id: props.id,
                title: props.title,
                preview: props.image.url,
                subtitle: props.station && props.station.title,
                uri: props.uri,
                playlist: props.playlist,
                dataType: DATA_TYPE_STATION_PODCAST,
                playing: true,
                artist: null,
                station: props.station, // station is owner of this podcast
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.PodcastSliderImageWrapper>
      <St.StationGridBottomTextSection>
        <St.PodcastSliderInfoWrapper>
          <St.PodcastsSliderTitle>{props.title}</St.PodcastsSliderTitle>
          <St.PodcastSliderReadMoreSection
            string={props.about}
            offset={getConfigValue(device, "podcasts.slider.textOffset")}
          />
          <LikeShare>
            <LikeAndShare
              shareURL={
                selectedCountry && selectedCountry.id
                  ? stationPodcastsShareURL(
                    props.station.id,
                    convertToSlug(props.title),
                    props.station.slug,
                    countrySlug,
                    image
                  )
                  : null
              }
              id={props.id}
              shareID={"allpodcasts_" + props.id}
              likeType={DATA_TYPE_STATION_PODCAST}
            />
          </LikeShare>
        </St.PodcastSliderInfoWrapper>
      </St.StationGridBottomTextSection>
    </St.PodcastSliderWrappper>
  )
}

export default PodcastCard
