import React from "react"
import styled from "styled-components"
import {
  PROP_FONT_SECONDARY,
  PROP_FONT_PRIMARY,
} from "../../../constants/props"
import { MOBILE, TAB, MEDIUM, LARGE } from "../../../constants/breakpoints"

const ScheduleContent = styled.div`
  margin-top: 10px;
`

const TimeSlotRow = styled.div`
  display: block;
  flex-direction: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${MOBILE.schedule.dayFont}px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;
  }
  @media (min-width: ${TAB.maxWidth}px) {
    width: ${TAB.contentWidth}px;
    font-size: ${MOBILE.schedule.dayFont}px;
  }
  @media (min-width: ${MEDIUM.maxWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.maxWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`

const TimeSlot = styled.span`
  color: #7b7d80;
`

const TitleSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
`

const ShowSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: #dededf;
  font-family: ${PROP_FONT_SECONDARY};
`

const ScheduleVList = props => {
  if (!props.data.length) return <TimeSlotRow>No schedules</TimeSlotRow>
  return (
    <ScheduleContent>
      {props.data.slice(0, 4).map((item, k) => (
        <span key={k}>
          <TimeSlotRow>
            <TimeSlot>{item.from + " to " + item.to}</TimeSlot>
            <TitleSpan>{item.title}</TitleSpan>
            {item.show && (
              <ShowSpan>&nbsp;•&nbsp;{item.show && item.show.title}</ShowSpan>
            )}
          </TimeSlotRow>
        </span>
      ))}
    </ScheduleContent>
  )
}

export default ScheduleVList
