import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import * as St from "../../Styled"
import {
  MEDIA_TYPE_VIDEO,
  DATA_TYPE_STATION_VIDEO,
  convertToSlug,
} from "../../../constants/props"
import useRecordUserData from "../../../hooks/useRecordUserData"

import { AppContext } from "../../../contexts/AppContext"
import { stationVideoDetailsShareURL } from "../../../constants/share"
import styled from "styled-components"
import LikeAndShare from "../../LikeAndShare"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`

const VideoCard = props => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, userState, ctxPlayer, selectedCountry } = useContext(AppContext)
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_STATION_VIDEO,
    id: props.id,
    user: userState ? userState.id : null,
    viewed: props.viewed != null ? props.viewed : 0,
  })

  const isPlaying =
    ctxPlayer.id === props.id &&
      ctxPlayer.dataType === DATA_TYPE_STATION_VIDEO &&
      ctxPlayer.playing
      ? true
      : false

  const image =
    props.image && props.image.url
      ? props.image.url.startsWith("http")
        ? props.image.url
        : basePrefix + props.image.url
      : ""

  return (
    <St.VideoSliderWrapper>
      <St.VideoSliderImageWrapper>
        <StyledImage
          ismobile={props.ismobile}
          image={props.image}
          width={"auto"}
          height={"100%"}
          premium={props.premium}
          borderRadius={"0px"}
          playing={isPlaying}
          imageAlt={props.title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id: props.id,
                title: props.title,
                preview: props.image.url,
                subtitle: props.station && props.station.title,
                uri: props.uri,
                playlist: props.playlist,
                station: props.station,
                artist: null, // Just to clear that station is the owner of playing video
                viewed: props.viewed != null ? props.viewed : 0,
                dataType: DATA_TYPE_STATION_VIDEO,
                playing: true,
              })
              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.VideoSliderImageWrapper>
      <St.StationGridBottomTextSection>
        <St.VideoSliderInfoWrapper>
          <St.VideoSliderTitle>{props.title}</St.VideoSliderTitle>
          <St.VideoSliderSingleLineText>
            {props.about ? props.about : ""}
          </St.VideoSliderSingleLineText>
          <St.VideoSliderSingleLineText>
            {props.subtitles && props.subtitles.length
              ? props.subtitles[1]
              : ""}
          </St.VideoSliderSingleLineText>
          <LikeShare>
            <LikeAndShare
              shareURL={
                selectedCountry && selectedCountry.id
                  ? stationVideoDetailsShareURL(
                    props.id,
                    convertToSlug(props.title),
                    props.station.id,
                    props.station.slug,
                    image
                  )
                  : null
              }
              id={props.id}
              likeType={DATA_TYPE_STATION_VIDEO}
              shareID={"allvideo_" + props.id}
            />
          </LikeShare>
        </St.VideoSliderInfoWrapper>
      </St.StationGridBottomTextSection>
    </St.VideoSliderWrapper>
  )
}

export default VideoCard
