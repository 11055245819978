import React, { useState, useContext } from "react"
import { AppContext } from "../../../contexts/AppContext"
import PresenterCard from "./PresenterCard"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
  DEVICE_MOBILE,
} from "../../../constants/breakpoints"
import { CARD_TYPE_PRESENTER, getConfigValue } from "../../../constants/props"
import SliderContainer from "../../SliderContainer"

const PresenterHList = props => {
  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "presenters.slider.slides")

  const [state, setState] = useState({
    data: props.data,
    showLeftArrow: false,
    showRightArrow: props.data.length - slidesToShow <= 0 ? false : true,
  })

  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow: LARGE.presenters.slider.slides,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    responsive: [
      {
        breakpoint: LARGE.minWidth,
        settings: {
          slidesToShow: MEDIUM.presenters.slider.slides,
        },
      },
      {
        breakpoint: MEDIUM.minWidth,
        settings: {
          slidesToShow: TAB.presenters.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          slidesToShow: MOBILE.presenters.slider.slides,
          initialSlide: state.data.length > 0 ? 1 : 0,
          className: 'sibling-visible', // would make overflow visible and hence would allow displaying parts of siblings
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow: state.data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }

  return (
    <SliderContainer
      addPadding={
        device === DEVICE_MOBILE &&
        state.data.length > MOBILE.presenters.slider.slides
          ? true
          : false
      }
      settings={settings}
      showLeftArrow={state.showLeftArrow}
      showRightArrow={state.showRightArrow}
      type={CARD_TYPE_PRESENTER}
    >
      {state.data.map((item, i) => (
        <PresenterCard key={i} {...item} />
      ))}
    </SliderContainer>
  )
}

export default PresenterHList
