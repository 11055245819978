import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import Query from "../../Query"
import { QUERY_ALL_STATIONS } from "../../../queries/page/station"
import { navigate } from "gatsby-link"

import StyledImage from "../../../components/StyledImage"
import ReadMorePara from "../../../components/ReadMorePara"
import * as C from "../common"
import * as St from "../../Styled"
import LikeAndShare from "../../LikeAndShare"
import btnAnchorPrev from "../../../images/prev.png"
import btnAnchorNext from "../../../images/next.png"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../../constants/breakpoints"
import {
  MEDIA_TYPE_AUDIO,
  stationDetailsURL,
  convertToSlug,
  COUNTRY_ALL,
  getConfigValue,
  DATA_TYPE_STATION,
  PROP_FONT_PRIMARY,
} from "../../../constants/props"

import useRecordUserData from "../../../hooks/useRecordUserData"

import { AppContext } from "../../../contexts/AppContext"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`
export const StationHeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: flex-end;
`

export const StationsHeading = styled.h3`
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 2px;
  color: #fff;
  margin-bottom: 0px;
  font-size: ${MOBILE.box.font.mainHeading}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.font.mainHeading}px;
  }
`
const DisableOverlay = styled.div`
  position: absolute;
  background: #ffffffa1;
  z-index: 9998;
  width: 100%;
  height: 100%;
  & span {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #454343c7;
    text-align: center;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1.5px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: ${MOBILE.box.station.featured.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    border-radius: ${LANDSCAPE.box.station.featured.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    border-radius: ${TAB.box.station.featured.image.borderRadius}px;
    & span {
      font-size: 16px;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-radius: ${MEDIUM.box.station.featured.image.borderRadius}px;
    & span {
      font-size: 20px;
    }
  }
  @media (min-width: ${LARGE.minWidth}px) {
    border-radius: ${LARGE.box.station.featured.image.borderRadius}px;
  }
`

const baseAmazonPrefix = `${process.env.AMAZON_BASE_PREFIX}`

const StationBox = ({ station }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const {
    updatePlayer,
    device,
    ctxPlayer,
    userState,
  } = useContext(AppContext)

  const recordUserData = useRecordUserData({
    user: userState ? userState.id : null,
    id: station ? station.id : null,
    type: DATA_TYPE_STATION,
  })

  const { id, streamuri, title, available, slug } = station

  useEffect(() => {
    /**
     * We will try to auto play station if there is no media is already added to player
     */
    if (id && !ctxPlayer.id) {
      updatePlayer({
        type: MEDIA_TYPE_AUDIO,
        id,
        title,
        streamuri,
        preview:
          station.image && station.image.url
            ? station.image.url.startsWith("http")
              ? station.image.url
              : basePrefix + station.image.url
            : "",
        subtitle: station && station.country.title,
        playlist: [],
        dataType: DATA_TYPE_STATION,
        playing: false,
      })
    }
  }, [])

  if (!station) return null

  let image =
    station.image && station.image.url
      ? station.image.url.startsWith("http")
        ? station.image.url
        : basePrefix + station.image.url
      : ""

  const isStationPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION &&
    ctxPlayer.playing
      ? true
      : false

  if (!image || image.trim().length === 0) {
    // show a default station image
    image = baseAmazonPrefix + "/static/default-station-image.jpg"
  }

  return (
    <St.StationDetailsWrapper>
      <St.StationDetailsImageWrapper>
        {!available && (
          <DisableOverlay>
            <span>Currently Offline</span>
          </DisableOverlay>
        )}
        <StyledImage
          image={image}
          width={"100%"}
          height={"100%"}
          premium={station.premium}
          shaded={true}
          playing={isStationPlaying}
          borderRadius={
            getConfigValue(device, "box.station.featured.image.borderRadius") +
            "px"
          }
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isStationPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                streamuri,
                preview:
                  station.image && station.image.url
                    ? station.image.url.startsWith("http")
                      ? station.image.url
                      : basePrefix + station.image.url
                    : "",
                subtitle: station && station.country.title,
                playlist: [],
                dataType: DATA_TYPE_STATION,
                playing: true,
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.StationDetailsImageWrapper>
      <St.StationDetailsRightTextSection>
        <St.StationDetailsSingleLineText>
          <div>Location:</div>
          <span>
            {station.country
              ? station.country.title + ", " + station.location
              : station.location}
          </span>
          <span></span>
        </St.StationDetailsSingleLineText>
        {station.frequencyByPlace && (
          <St.StationDetailsMultilineText>
            <div>Broadcast Frequency:</div>
            <span>{station.frequencyByPlace}</span>
          </St.StationDetailsMultilineText>
        )}
        <St.StationDetailsSingleLineText>
          <div>Contact:</div>
          <span>{station.contact}</span>
        </St.StationDetailsSingleLineText>
        <St.StationDetailsMultilineText>
          <div>About:</div>
          <div>
            <ReadMorePara string={station.about} offset={100} />
          </div>
        </St.StationDetailsMultilineText>
        <St.FeaturedSocialIconSection>
          <LikeShare>
            <LikeAndShare
              shareID={"stationdetails_" + id}
              id={id}
              likeType={DATA_TYPE_STATION}
              shareURL={stationDetailsURL(slug)}
              copyEmbedCode={true}
            />
          </LikeShare>
        </St.FeaturedSocialIconSection>
      </St.StationDetailsRightTextSection>
    </St.StationDetailsWrapper>
  )
}

export const StationBoxParent = ({
  station,
  stations
}) => {
  let currentIndex = -1
  if (station && station.id && stations && stations.length) {
    currentIndex = stations.findIndex(s => {
      return s.id === station.id
    })
  }

  const selectPrevious = () => {
    if (
      stations &&
      stations.length &&
      currentIndex !== -1 &&
      stations.length > 1
    ) {
      const idx = (currentIndex - 1 + stations.length) % stations.length
      navigate(stationDetailsURL(stations[idx].slug))
    }
  }

  const selectNext = () => {
    if (
      stations &&
      stations.length &&
      currentIndex !== -1 &&
      stations.length > 1
    ) {
      const idx = (currentIndex + 1) % stations.length
      navigate(stationDetailsURL(stations[idx].slug))
    }
  }

  return (
    <C.GrayBox>
      <C.GrayBoxContent>
        <StationHeadingWrapper>
          <StationsHeading>{station.title}</StationsHeading>
          <C.PreviousNextWrapper>
            <C.PrevAnchor
              disabled={
                currentIndex === -1 ||
                !stations ||
                !stations.length ||
                stations.length === 1
                  ? true
                  : false
              }
              src={btnAnchorPrev}
              onClick={selectPrevious}
            />
            <C.NextAnchor
              disabled={
                currentIndex === -1 ||
                !stations ||
                !stations.length ||
                stations.length === 1
                  ? true
                  : false
              }
              src={btnAnchorNext}
              onClick={selectNext}
            />
          </C.PreviousNextWrapper>
        </StationHeadingWrapper>
        <StationBox station={station} />
      </C.GrayBoxContent>
    </C.GrayBox>
  )
}

const StationBoxData = ({ station }) => {
  // Fetch all the stations of selected country so that we can iterate over them when arrows clicked
  const { selectedCountry } = useContext(AppContext)
  let criteria = { active: true }
  if (
    selectedCountry &&
    selectedCountry.id &&
    selectedCountry.name !== COUNTRY_ALL
  ) {
    criteria["country_in"] = selectedCountry.id
  }

  return (
    <Query
      query={QUERY_ALL_STATIONS}
      variables={{ where: criteria, order: "title:asc" }}
      fetchPolicy="network-only"
      returnLoading={true}
    >
      {({ data, loading }) => {
        return !loading ? (
          <StationBoxParent
            station={station}
            stations={data.stations}
            countryID={
              selectedCountry && selectedCountry.id
                ? selectedCountry.id
                : COUNTRY_ALL
            }
            countrySlug={
              selectedCountry && selectedCountry.name
                ? convertToSlug(selectedCountry.name)
                : COUNTRY_ALL
            }
          ></StationBoxParent>
        ) : (
          <StationBoxParent
            station={station}
            stations={[station]}
            countryID={
              selectedCountry && selectedCountry.id
                ? selectedCountry.id
                : COUNTRY_ALL
            }
            countrySlug={
              selectedCountry && selectedCountry.name
                ? convertToSlug(selectedCountry.name)
                : COUNTRY_ALL
            }
          ></StationBoxParent>
        )
      }}
    </Query>
  )
}

export default StationBoxData
